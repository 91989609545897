<template>
  <div v-if="latestReserves.length > 0" class="top-invoices" :class="isMobile ? 'mobile' : ''">
    <h3 class="header">
      {{ $t('report.tops.reserve.title') }}
    </h3>
    <div class="list-header">
      <span class="row-number">
      {{ $t('report.tops.reserve.table.header.row') }}
      </span>
      <span class="name">
      {{ $t('report.tops.reserve.table.header.name') }}
      </span>
      <span class="date">
      {{ $t('report.tops.reserve.table.header.date') }}
      </span>
      <span v-if="!isMobile" class="court">
      {{ $t('report.tops.reserve.table.header.court') }}
      </span>
      <span v-if="!isMobile" class="time">
      {{ $t('report.tops.reserve.table.header.time') }}
      </span>
      <span v-if="isMobile" class="show">
      {{ $t('report.tops.reserve.table.header.show') }}
      </span>
    </div>
    <ul class="invoices-list">
      <template v-for="(invoice, key) in latestReserves.slice(0, 10)">
        <li :key="key" class="invoice" @click="$router.push({name: 'saleInvoice', params: {id: invoice.id}})">
          <span class="row-number">
            {{ key + 1 }}
          </span>
          <span class="name">{{ invoice.userName }}</span>
          <span class="date">
            {{ invoice.date }}
          </span>
          <span v-if="!isMobile" class="court">
            {{ invoice.court }}
          </span>
          <span v-if="!isMobile" class="time">
            {{ invoice.time }}
          </span>
          <span v-if="isMobile" class="show">
            <custom-icon icon="EYE" height="20px" width="20px" />
          </span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
  import {addComma, getAvatarUrl, getStaticFileUrl} from "../../../../assets/js/functions";
  import {getSaleInvoices} from "../../../../http/requests/sales";
  import CustomIcon from "../../../../components/customIcon/customIcon";

  export default {
    name: "latestReserves",
    components: {CustomIcon},
    data () {
      return {
        latestReserves: []
      }
    },
    computed: {
      isMobile() {
        return this.$store.state.windowWidth <= this.$store.state.mobileScreen
      },
    },
    created () {
      this.getLatestReserve()
    },
    methods: {
      getLatestReserve () {
        getSaleInvoices(1,['order[0]=created_at,desc']).then((response) => {
          const invoices = response.data.data
          invoices.forEach((item) => {
            this.latestReserves.push({
              id: item.id,
              userName: `${item.user.name || ''} ${item.user.family || ''}`,
              phoneNumber: item.user.phone_number,
              date: item.reserved_time.date,
              court: item.reserved_time.court.name.split(this.$t('report.tops.reserve.labels.court')).join('').trim(),
              time: `${parseInt(item.reserved_time.time.end_time.split(':')[0])} - ${parseInt(item.reserved_time.time.start_time.split(':')[0])}`,
              finalPrice: addComma(item.total_price)
            })
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .top-invoices {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: calc((100vh - 250px));
    min-height: 370px;
    box-shadow: 0 0 10px -5px;
    border-radius: 1rem;
    overflow: hidden;


    .header {
      position: relative;
      font-size: 18px;
      text-align: left;
      padding: 10px 15px 17px;
      margin-bottom: 10px;
      height: 45px;

      &:after {
        content: ' ';
        width: 50px;
        height: 5px;
        background-color: #48B4E0;
        display: block;
        margin: auto;
        border-radius: 0.5rem;
        position: absolute;
        bottom: 0;
        left: 15px;
      }
    }

    .list-header {
      display: flex;
      align-items: center;
      color: #cdcdcd;
      border-bottom: 1px solid #cdcdcd;
      margin: 0 15px;
      height: 40px;
      font-weight: 500;

      .row-number {
        width: 40px;
        text-align: center;
      }

      .name {
        display: block;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc((100% - 40px) / 5 * 2);
      }

      .phone-number {
        display: block;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc((100% - 40px) / 5);
      }

      .date {
        display: flex;
        justify-content: center;
        text-align: center;
        width: calc((100% - 40px) / 5);
      }

      .court {
        display: flex;
        justify-content: center;
        text-align: center;
        width: calc((100% - 40px) / 5);
      }

      .time {
        display: flex;
        justify-content: center;
        text-align: center;
        width: calc((100% - 40px) / 5);
      }
    }

    .invoices-list {
      height: calc(100% - 45px);
      padding-left: 15px;
      padding-right: 8px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: block;
        border-radius: .2rem;
        width: 8px;
        background: #f9f9fd;
        right: 8px;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: .2rem;
        background: #cecece;
      }

      .invoice {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 5px 0;
        border-bottom: 1px solid #cecece;
        color: #000000;
        font-size: 14px;
        height: 45px;

        &:hover {
          cursor: pointer;
        }


        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .row-number {
          width: 40px;
          text-align: center;
        }

        .name {
          width: calc((100% - 40px) / 5 * 2);
          display: block;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .phone-number {
          width: calc((100% - 40px) / 5);
        }

        .date {
          width: calc((100% - 40px) / 5);
        }

        .court {
          display: block;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc((100% - 40px) / 5);
        }

        .time {
          width: calc((100% - 40px) / 5);
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &.mobile {

      .list-header {
        .row-number {
          width: 40px;
          text-align: center;
        }

        .name {
          width: calc(100% - 170px);
        }

        .date {
          width: 80px;
        }

        .show {
          width: 50px;
        }
      }

      .invoice {
        .row-number {
          width: 40px;
          text-align: center;
        }

        .name {
          width: calc(100% - 170px);
        }

        .date {
          width: 80px;
        }

        .show {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          text-align: center;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
</style>
