<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="dashboard" :class="isMobile ? 'mobile' : ''">
      <div class="section-column">
        <!-- this main chart show for mobile mode -->
        <div v-if="checkUserPermissions('report_chart.show') && isMobile" class="chart">
          <main-chart/>
        </div>

        <div class="shortcuts">
          <div v-if="checkUserPermissions('calendar.show')" class="shortcut-box" @click="handleClickShortcuts('saleCalendar')">
            <div class="shortcut-icon">
              <custom-icon icon="CALENDER" :color-code="'#828282'" height="40px" width="40px"/>
            </div>
            <p class="text-secondary">{{ $t('shortcuts.calendar') }}</p>
          </div>

          <div v-if="checkUserPermissions('invoice.create')" class="shortcut-box" @click="handleClickShortcuts('insertSaleInvoice')">
            <div class="shortcut-icon">
              <custom-icon icon="BUY" :color-code="'#57bae2'" height="40px" width="40px"/>
            </div>
            <p class="text-primary">{{ $t('shortcuts.reserve') }}</p>
          </div>

          <div v-if="checkUserPermissions('receive.create')" class="shortcut-box" @click="handleClickShortcuts('insertTreasuryReceive')">
            <div class="shortcut-icon">
              <custom-icon icon="MONEY" color="success" height="40px" width="40px"/>
            </div>
            <p class="text-success">{{ $t('shortcuts.receive') }}</p>
          </div>
        </div>

        <div class="tops">
          <div v-if="checkUserPermissions('report_tops.show')" class="top-list">
            <latest-reserves/>
          </div>
        </div>
      </div>

      <div class="section-column main">
        <!-- this main chart show for desktop mode -->
        <div v-if="checkUserPermissions('report_chart.show') && !isMobile" class="chart">
          <main-chart/>
        </div>

        <div class="section-row">
          <div v-if="checkUserPermissions('report_tops.show')" class="top-list">
            <top-customers/>
          </div>
          <div v-if="checkUserPermissions('report_tops.show')" class="top-list">
            <top-courts/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopCustomers from "./reports/tops/topCustomers";
  import TopCourts from "./reports/tops/topCourts";
  import {checkUserPermissions} from "../../assets/js/functions";
  import MainChart from "./reports/charts/mainChart";
  import CustomIcon from "../../components/customIcon/customIcon";
  import LatestReserves from "./reports/tops/latestReserves";

  export default {
    metaInfo () {
      return {
        title: this.$t('dashboard')
      }
    },
    components: {
      LatestReserves,
      CustomIcon,
      MainChart,
      TopCourts,
      TopCustomers,
    },
    data() {
      return {
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60
        }
      }
    },
    computed: {
      isMobile() {
        return this.$store.state.windowWidth <= this.$store.state.mobileScreen
      },
    },
    mounted() {
      this.$store.dispatch('auth/setAccessToken')
      /*const scroll_el = this.$refs.chatLogPS.$el || this.$refs.chatLogPS
      scroll_el.scrollTop = this.$refs.chatLog.scrollHeight*/
    },
    created() {
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },

      handleClickShortcuts(routeName) {
        this.$store.dispatch('setPageTitle', '')
        this.$router.push({name: routeName})
      }
    }
  }
</script>

<style lang="scss">
  .chat-card-log {
    height: 400px;

    .chat-sent-msg {
      background-color: #f2f4f7 !important;
    }
  }

  .dashboard {
    display: flex;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      display: block;
      border-radius: .2rem;
      width: 8px;
      background: #f9f9fd;
      right: 8px;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      border-radius: .2rem;
      background: #cecece;
    }

    .section-column {
      padding: 10px;

      &.main {
        width: 70%;

        .chart {
          height: calc((100vh - 105px) / 2);
          padding-bottom: 20px;
          min-height: 250px;
        }
      }

      &:first-of-type {
        width: 30%;

        .chart {
          height: calc((100vh - 100px) / 3);
          min-height: 250px;
          padding-bottom: 20px;
        }

        .shortcuts {
          /*display: grid;*/
          /*grid-template-columns: auto auto auto;*/
          display: flex;
          height: 110px;
          margin-bottom: 20px;


          .shortcut-box {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            flex-grow: 1;
            box-shadow: 0 0 10px -5px;
            border-radius: 1rem;
            background-color: #ffffff;

            &:hover {
              cursor: pointer;
            }

            .shortcut-icon {

            }

            p {
              font-weight: bold;
              margin-top: 5px;
            }

            &:nth-child(2) {
              margin: 0 20px;
            }
          }
        }

        .tops {
          display: flex;
          width: 100%;
          flex-direction: column;

          .top-list {
            width: 100%;
            height: calc((100vh - 250px));

            &:first-child {
              padding-bottom: 20px;
            }
          }
        }
      }

      .section-row {
        display: flex;
        flex-wrap: wrap;

        .top-list {
          width: calc(50% - 10px);

          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }

    &.mobile {
      flex-direction: column;

      .section-column {
        width: 100%;

        .section-row {
          .top-list {
            width: 100%;
            margin-bottom: 20px;

            &:first-child {
              margin-right: 0;
            }
          }
        }
      }

      .section-row {
        .chart {
          width: 100%;
        }

        .tops {
          width: 100%;

          .top-list {
            width: 100%;
          }
        }
      }
    }
  }
</style>
